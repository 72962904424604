// extracted by mini-css-extract-plugin
export var processBoxFix = "process-module--processBoxFix--1SZfF";
export var processBoxScroll = "process-module--processBoxScroll--34AAH";
export var processContent = "process-module--processContent--2csuV";
export var processTitle = "process-module--processTitle--GJQmK";
export var processGrid = "process-module--processGrid--3Bxun";
export var row = "process-module--row--2c9vl";
export var processImg = "process-module--processImg--1NKLj";
export var processCaption = "process-module--processCaption--fsgY9";
export var processCarousel = "process-module--processCarousel--2F1xI";
export var tagsContainer = "process-module--tags-container--3gM7d";
export var tag = "process-module--tag--1bFsd";
export var processMain = "process-module--processMain--3OE7R";